/* Header Logo */
@media only screen and (min-width: 1200px) {
    .navbar-brandd {
      display: none;
    }
}

@media (min-width: 200px) and (max-width: 900px) {
    .navbar-branddd {
      display: none;
      margin-left: 60px;
    }
}
@media (min-width: 200px) and (max-width: 1000px) {
    .khalidmedia {
      text-align: center;
    }
  }
@media (max-width: 700px) and (min-width: 200px) {
    .site-brand {
        align-items: center;
      text-align: center;
      
    }
}

@media (max-width: 700px) and (min-width: 200px) {
    .logofix {
        margin-left:0px
      
    }
}

@media only screen and (min-width: 1200px) {
    .logofix {
        margin-left:56px
      
    }
}

/* Header Logo End */