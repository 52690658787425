
img {
    max-width: 100%;
    height: auto;
  }
  
  h2 {
    font-family: 'Nunito', sans-serif;
    color: #111111;
    margin: 0 0 26px;
    line-height: 1.2;
  }
  h2 {
    font-size: 36px;
    font-weight: 700;
  }
  a {
    color: #21a7d0;
    transition: all 0.3s ease;
    text-decoration: none !important;
    outline: none !important;
  }
  a:active,
  a:hover {
    text-decoration: none;
    outline: 0 none;
    color: #21a7d0;
  }
  ::selection {
    background: #21a7d0;
    text-shadow: none;
    color: #ffffff;
  }
  /*-- Blog Button Css --*/
  /* ------------------------------------
      02. Global Class CSS
  ---------------------------------------*/
  .readon2 {
    outline: none;
    border: none;
    padding: 12px 40px;
    border-radius: 3px;
    display: inline-block;
    text-transform: capitalize;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: #ffffff;
    background: #21a7d0;
    position: relative;
    overflow: hidden;
  }
  .readon2.mod {
    text-transform: uppercase;
    padding: 14px 31px;
  }
  .readon2:hover {
    background: #1e95ba;
    color: #ffffff;
  }
  /*Pagination*/
  /*Video Icon*/
  .sec-title .sub-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .sec-title .sub-title.primary {
    color: #21a7d0;
  }
  /* -----------------------
      03. Header Section CSS
  --------------------------*/
  /*------------------------------------
      04. Sticky Menu CSS
  --------------------------------------*/
  @keyframes sticky-animation {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  /* ------------------------------------
      05. Banner Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      06. Slider Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      06. Breadcrumbs Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      07. About Section CSS
  ---------------------------------------*/
  .rs-about.style1 .img-part {
    position: relative;
  }
  .rs-about.style1 .img-part .shape {
    position: absolute;
  }
  .rs-about.style1 .img-part .top-center {
    top: -30px;
    left: 49%;
    z-index: -1;
  }
  /* ------------------------------------
      08. Services Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      09. Subject Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      10. Categories Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      11. Popular Courses Section CSS
  ---------------------------------------*/
  /* -----------------------------------
      12. Course Single Section CSS
  -------------------------------------*/
  /*Intro Section*/
  /* Course Overview */
  /*Course Review*/
  /* -----------------------------------
      13. Accordion Section CSS
  -------------------------------------*/
  /* ------------------------------------
      14. Why Choose US Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      15. Team Section CSS
  ---------------------------------------*/
  /* -----------------------------------
      16. Team Single Section CSS
  -------------------------------------*/
  /* ------------------------------------
      17. Testimonial Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      18. Blog Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      19. Latest Events Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      19. Download Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      20. Partner Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      21. Gallery Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      22. CTA Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      23. Counter Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      24. Newsletter Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      25. Publication Section CSS
  ---------------------------------------*/
  /* ------------------------------------
      26. Facilities Section CSS
  ---------------------------------------*/
  /*----------------------------------------
      27. Faq Section CSS
  ----------------------------------------*/
  /* ------------------------------------
      28. Error Section CSS
  ---------------------------------------*/
  /*----------------------------------------
      29. Shop Section CSS
  ----------------------------------------*/
  /*----------------------------------------
      30. Single Shop Section CSS
  ----------------------------------------*/
  /*----------------------------------------
      31. Cart Section CSS
  ----------------------------------------*/
  /*-----------------------------------------------
      32. Header Cart Section CSS
  -----------------------------------------------*/
  /*-----------------------------------------------
      28. Woocommerce Section CSS
  -----------------------------------------------*/
  /*----------------------------------------
      33. Checkout Section CSS
  ----------------------------------------*/
  /*----------------------------------------
      34. Login Section CSS
  ----------------------------------------*/
  /* -----------------------------------
      35. Register Section CSS
  -------------------------------------*/
  /* Styled Form */
  /*-------------------------------------
      36. Contact Section CSS
  --------------------------------------*/
  /*------------------------------------
      37. Footer Section CSS
  ------------------------------------*/
  /* -----------------------
      38. Scroll Up CSS
  --------------------------*/
  /*Pulse Border Animation*/
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  /*Pulse Border Animation*/
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  @keyframes circle-ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 84, 33, 0.3), 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3);
    }
    100% {
      box-shadow: 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3), 0 0 0 8em rgba(255, 84, 33, 0);
    }
  }
  @keyframes up-down {
    0% {
      transform: translateY(30px);
      -webkit-transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
      -webkit-transform: translateY(-50px);
    }
    100% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
    }
  }
  @keyframes left-right {
    0% {
      transform: translatex(0);
      -webkit-transform: translatex(0);
    }
    50% {
      transform: translateY(-50px);
      -webkit-transform: translatex(-50px);
    }
    100% {
      transform: translatex(0);
      -webkit-transform: translatex(0);
    }
  }
  @keyframes spine {
    0% {
      transform: rotate(0);
      -webkit-transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spine-r {
    0% {
      transform: rotate(0);
      -webkit-transform: rotate(0);
    }
    100% {
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spine2 {
    from {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    from {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spine2 {
    from {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    from {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes rotated-style {
    0% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes veritcal-two {
    0% {
      transform: translateY(30px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(30px);
    }
  }
  /* ------------------------------------
      39. Preloader CSS
  ---------------------------------------*/
  @keyframes loaderspin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes loaderpulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  
  
  
  @charset "utf-8";
  
  
  
  @media only screen and (max-width: 1366px) and (min-width: 1200px) {
  }
  
  @media only screen and (min-width: 1440px) {
      .container {
          max-width: 1270px;
          width: 100%;
      }
  }
  
  @media only screen and (max-width: 1366px) {
      .container {
          max-width: 1170px;
          width: 100%;
      }
  }
  
  @media only screen and (max-width: 1199px) {
      .container {
          max-width: 960px;
      }
      .sec-title .title {
          font-size: 35px;
      }
  }
  
  @media only screen and (max-width: 991px) {
      .order-last {
          -ms-flex-order: unset;
          order: unset;
      }
      .container {
          max-width: 720px;
      }
  
      /*Menu Part Responsive */
  
      .sec-title .title {
          font-size: 30px;
      }
  }
  
  @media only screen and (max-width: 767px) {
   
      .container {
          max-width: 540px;
      }
      .sec-title .title {
          font-size: 30px;
      }
      .sec-title .sub-title {
          font-size: 16px;
      }
  }
  
  @media only screen and (max-width: 480px) {
      .sec-title .title {
          font-size: 25px;
      }
  }
  
  