
  .ui-center {
    text-align: center;
  }
  
  
  
  .carousel-container {
    
    position: relative;
    height: 300px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    touch-action: pan-y;
  }
  
  .carousel-track {
    
    display: flex;
    height: 100%;
  }
  
  .carousel-card {
    flex: 0 0 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: white;
    -webkit-tap-highlight-color: transparent;
  }
  
  .carousel-card-inner {
    position: relative;
    flex-direction: column;
    width: 290px;
    height: 290px;
    border-radius: 5px;
  }
  
  .carousel-title {
    margin-top: 20px;
    font-size: 1.5em;
  }
  
  .carousel-text {
    padding: 1em;
    white-space: pre-wrap;
    
    font-family: sans-serif;
  }

  /* About Home  */


  .card-object {
    
    display: grid;
    grid-template: "header header"
                    "aside  main"
                   "foot  foot";
    font-family: courier;
    font-size: 24px;
    line-height: 40px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  }
  
  .card-object header {
    grid-column: header;
    height: 40px;
    background-image: url(https://image.ibb.co/b49eUb/dechirure.png);
    background-repeat: repeat;
  }
  
  .card-object aside {
    grid-area: aside;
    width: 40px;
    background-image: url(https://image.ibb.co/fpgtpb/trou.png);
    background-repeat: repeat;
  }
  
  .card-object main {
    grid-area: main;
    background-color: white;
  }
  
  .card-object main .message{
      background-image: url(https://image.ibb.co/iA4Dpb/line.png);
    background-repeat: repeat;
    background-position: 0 -10px;
    margin: 20px 20px 0 20px;
  }
  
  .card-object footer {
    grid-column: foot;
    text-align: right;
    background-color: white;
    padding: 5px 20px 20px 0;
    border-radius: 0 0 5px 5px;
  }
  
  .author::before{
    content: "- ";
  }
  
  .rotate{
    animation: rotate 14s infinite ease-in-out;
    
  }
  
  .floating{
    animation: float 14s infinite ease-in-out;
  }
  
  @keyframes rotate{
    0% {
    transform: perspective(1000px) rotateY(4deg);
    }
    50%{
    transform: perspective(1000px) rotateX(-5deg);
    }
    100% {
    transform: perspective(1000px) rotateY(4deg);
    }
  }
  
  @keyframes float{
    0% {
    transform: perspective(1000px) translateY(0px) rotateX(5deg);
    }
    50%{
    transform: perspective(1000px) translateY(10px) rotateX(-5deg);
    }
    100% {
    transform: perspective(1000px) translateY(0px) rotateX(5deg);
    }
  }
  
  .forget{
    opacity: 0.2;
    animation: disappear 5s infinite ease-in-out;
  }
  
  @keyframes disappear{
    0%{
      opacity: 0.6;
    }
    50%{
      opacity: 0.1;
    }
    100%{
      opacity: 0.6;
    }
  }
  
  .forgotten{
      opacity: 0.1;
      background-color: black;
  }
  
  .close_btn {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #26262f;
    color: red;
    display: inline-block;
    padding: 1em;
    font-size: 1.4rem;
    letter-spacing: 0.15em;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    right: 20px;
    top:20px;
  }
  
  h2 {
    color: #2a435d;
    font-size: 42px;
    font-weight: 700; }
  
  h2.home-2 {
    font-family: "Playfair Display", serif; }
  
  h3 {
    color: #374a5e;
    font-size: 30px;
    font-weight: 700; }
  
  h4 {
    color: #374a5e;
    font-size: 24px;
    font-weight: 700; }
  
  h4.home-2 {
    font-family: "Playfair Display", serif; }
  
  
  h2,
  h3,
  h4 {
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    line-height: 1.2;
    margin-bottom: 0; }
  
  p {
    color: #374a5e;
    margin-bottom: 10px; }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  
  a:hover,
  a:focus {
    text-decoration: none;
    outline: none; }
  

  
  /* About Home ENd */


  .countdown-section {
    position: relative;
    padding-bottom: 40px;
  }
    .countdown-section:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: rgba(80, 190, 70, 0.1); }
    .countdown-section .common-section {
      padding: 50px 60px; }
      @media (min-width: 576px) and (max-width: 767.98px) {
        .countdown-section .common-section {
          padding: 40px; } }
      @media (max-width: 575.98px) {
        .countdown-section .common-section {
          padding: 30px; } }
    .countdown-section h2.home-2 span {
      color: #50be46; }
    .countdown-section .countdown-right {
      float: right; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .countdown-section .countdown-right {
          float: none;
          margin-top: 30px; } }
      @media (min-width: 576px) and (max-width: 767.98px) {
        .countdown-section .countdown-right {
          float: none;
          margin-top: 30px; } }
      @media (max-width: 575.98px) {
        .countdown-section .countdown-right {
          float: none;
           } }
    .countdown-section ul.countdown {
      text-align: center; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .countdown-section ul.countdown {
          text-align: left; } }
      @media (min-width: 576px) and (max-width: 767.98px) {
        .countdown-section ul.countdown {
          text-align: left; } }
      @media (max-width: 575.98px) {
        .countdown-section ul.countdown {
          text-align: left; } }
      .countdown-section ul.countdown li {
        display: inline-block;
        margin: 0 5px; }
        .countdown-section ul.countdown li span {
          font-size: 42px;
          font-weight: 700;
          color: #50be46; }
        .countdown-section ul.countdown li p {
          text-transform: capitalize;
          font-weight: 500; }
      .countdown-section ul.countdown .separator {
        font-size: 42px;
        line-height: 60px;
        vertical-align: top;
        color: #50be46; }  



.card-object {
  display: grid;
  grid-template: "header header"
                  "aside  main"
                 "foot  foot";
  font-family: courier;
  font-size: 24px;
  line-height: 40px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
}

.card-object header {
  grid-column: header;
  height: 40px;
  background-image: url(https://image.ibb.co/b49eUb/dechirure.png);
  background-repeat: repeat;
}

.card-object aside {
  grid-area: aside;
  width: 40px;
  background-image: url(https://image.ibb.co/fpgtpb/trou.png);
  background-repeat: repeat;
}

.card-object main {
  grid-area: main;
  background-color: white;
}

.card-object main .message{
    background-image: url(https://image.ibb.co/iA4Dpb/line.png);
  background-repeat: repeat;
  background-position: 0 -10px;
  margin: 20px 20px 0 20px;
}

.card-object footer {
  grid-column: foot;
  text-align: right;
  background-color: white;
  padding: 5px 20px 20px 0;
  border-radius: 0 0 5px 5px;
}

.author::before{
  content: "- ";
}

.rotate{
  animation: rotate 14s infinite ease-in-out;
  
}

.floating{
  animation: float 14s infinite ease-in-out;
}

@keyframes rotate{
  0% {
  transform: perspective(1000px) rotateY(4deg);
  }
  50%{
  transform: perspective(1000px) rotateX(-5deg);
  }
  100% {
  transform: perspective(1000px) rotateY(4deg);
  }
}

@keyframes float{
  0% {
  transform: perspective(1000px) translateY(0px) rotateX(5deg);
  }
  50%{
  transform: perspective(1000px) translateY(10px) rotateX(-5deg);
  }
  100% {
  transform: perspective(1000px) translateY(0px) rotateX(5deg);
  }
}

.forget{
  opacity: 0.2;
  animation: disappear 5s infinite ease-in-out;
}

@keyframes disappear{
  0%{
    opacity: 0.6;
  }
  50%{
    opacity: 0.1;
  }
  100%{
    opacity: 0.6;
  }
}

.forgotten{
    opacity: 0.1;
    background-color: black;
}

.close_btn {
  width: 20px;
  height: 20px;
  position: absolute;
  background: #26262f;
  color: red;
  display: inline-block;
  padding: 1em;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  right: 20px;
  top:20px;
}

h2 {
  color: #2a435d;
  font-size: 42px;
  font-weight: 700; }

h2.home-2 {
  font-family: "Playfair Display", serif; }

h3 {
  color: #374a5e;
  font-size: 30px;
  font-weight: 700; }

h4 {
  color: #374a5e;
  font-size: 24px;
  font-weight: 700; }

h4.home-2 {
  font-family: "Playfair Display", serif; }


h2,
h3,
h4 {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  line-height: 1.2;
  margin-bottom: 0; }

p {
  color: #374a5e;
  margin-bottom: 10px; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

a:hover,
a:focus {
  text-decoration: none;
  outline: none; }

img {
  width: 100%;
  height: auto;
   
}

/* Navbar */

/* Header Section */

/* Footer Section */

/* Breadcrumb Section */

/* Preloader */

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* Button */
.template-button {
  color: #ffffff;
  background-color: #50be46;
  padding: 12px 24px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: inline-block; }
  .template-button:hover {
    color: #50be46;
    background-color: transparent;
    border: 1px solid #50be46; }
  .template-button i {
    margin-left: 5px; }

/* Form */

/* Accordion */

/* Theme Default */
.section-title {
  margin-bottom: 50px; 
  padding-top: 40px;
}
  .section-title h2 span {
    color: #50be46; }

.gradient-bg {
  position: relative;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(80, 190, 70, 0.1)), to(#ffffff));
  background-image: -webkit-linear-gradient(rgba(80, 190, 70, 0.1), #ffffff);
  background-image: -o-linear-gradient(rgba(80, 190, 70, 0.1), #ffffff);
  background-image: linear-gradient(rgba(80, 190, 70, 0.1), #ffffff); }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@keyframes zooming {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(80, 190, 70, 0.2), 0 0 0 10px rgba(80, 190, 70, 0.2), 0 0 0 20px rgba(80, 190, 70, 0.2);
    box-shadow: 0 0 0 0 rgba(80, 190, 70, 0.2), 0 0 0 10px rgba(80, 190, 70, 0.2), 0 0 0 20px rgba(80, 190, 70, 0.2); }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(80, 190, 70, 0.2), 0 0 0 20px rgba(80, 190, 70, 0.1), 0 0 0 40px rgba(80, 190, 70, 0);
    box-shadow: 0 0 0 20px rgba(80, 190, 70, 0.2), 0 0 0 20px rgba(80, 190, 70, 0.1), 0 0 0 40px rgba(80, 190, 70, 0); } }


/* Home Page 01 */

.counterup-section .single-counterup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px; }
  .counterup-section .single-counterup .single-counterup-image {
    margin-right: 10px; }
    .counterup-section .single-counterup .single-counterup-image img {
      width: auto;
      height: auto;
      max-height: 80px; }
  .counterup-section .single-counterup .single-counterup-content .counter-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .counterup-section .single-counterup .single-counterup-content span {
    text-transform: capitalize; }

.common-section {
  position: relative;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(rgba(80, 190, 70, 0.1)));
  background-image: -webkit-linear-gradient(#ffffff, rgba(80, 190, 70, 0.1));
  background-image: -o-linear-gradient(#ffffff, rgba(80, 190, 70, 0.1));
  background-image: linear-gradient(#ffffff, rgba(80, 190, 70, 0.1));
  padding-left: 40px;
  z-index: 1;
  -webkit-box-shadow: 0 20px 40px #eaeaea;
  box-shadow: 0 20px 40px #eaeaea; }
  .common-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .common-section .counter-shape img {
    width: auto;
    height: auto; }
    .common-section .counter-shape img.round-shape-2 {
      position: absolute;
      top: 40%;
      left: 20px; }
    .common-section .counter-shape img.plus-sign {
      position: absolute;
      bottom: 10px;
      left: 50%; }
    .common-section .counter-shape img.round-shape-3 {
      position: absolute;
      top: 40%;
      right: 20px; }

.instructor-section {
  position: relative;
  /* padding: 215px 0 0;
  margin-top: -100px;  */
}

.single-instructor {
  position: relative;
  padding: 20px 20px 30px;
  background-color: #effefb;
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  margin-bottom: 30px; }
  .single-instructor:hover {
    -webkit-box-shadow: 0 20px 40px #eaeaea;
    box-shadow: 0 20px 40px #eaeaea; }
    .single-instructor:hover .hover-state {
      bottom: 0;
      opacity: 1;
      visibility: visible; }
  .single-instructor.diffrent-bg-color {
    background-color: #fffcf6; }
  .single-instructor .instructor-sign {
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: 30px;
    font-family: "Caveat", cursive;
    font-weight: 700;
    text-transform: capitalize;
    color: #374a5e;
    opacity: .2; }
  .single-instructor .instructor-content {
    margin-top: 20px; }
    .single-instructor .instructor-content span {
      display: inline-block;
      margin-top: 5px;
      text-transform: capitalize; }
    .single-instructor .instructor-content h4 a {
      color: #374a5e;
      -webkit-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in; }
      .single-instructor .instructor-content h4 a:hover {
        color: #50be46; }
  .single-instructor .hover-state {
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    position: relative;
    bottom: -5px;
    opacity: 0;
    visibility: hidden; }
    .single-instructor .hover-state ul {
      margin-top: 10px; }
      .single-instructor .hover-state ul li {
        display: inline-block;
        margin: 0 5px; }
        .single-instructor .hover-state ul li a {
          color: #374a5e;
          -webkit-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in; }
          .single-instructor .hover-state ul li a:hover {
            color: #50be46; }



            .our-team {
              padding: 30px 0 40px;
              margin-bottom: 30px;
              background-color: #f7f5ec;
              text-align: center;
              overflow: hidden;
              position: relative;
            }
            
            .our-team .picture {
              display: inline-block;
              height: 130px;
              width: 130px;
              margin-bottom: 50px;
              z-index: 1;
              position: relative;
            }
            
            .our-team .picture::before {
              content: "";
              width: 100%;
              height: 0;
              border-radius: 50%;
              background-color: #1369ce;
              position: absolute;
              bottom: 135%;
              right: 0;
              left: 0;
              opacity: 0.9;
              transform: scale(3);
              transition: all 0.3s linear 0s;
            }
            
            .our-team:hover .picture::before {
              height: 100%;
            }
            
            .our-team .picture::after {
              content: "";
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #1369ce;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
            }
            
            .our-team .picture img {
              width: 100%;
              height: auto;
              border-radius: 50%;
              transform: scale(1.4);
              transition: all 0.9s ease 0s;
            }
            
            .our-team:hover .picture img {
              box-shadow: 0 0 0 14px #f7f5ec;
              transform: scale(0.7);
            }
            
            .our-team .title {
              display: block;
              font-size: 15px;
              color: #4e5052;
              text-transform: capitalize;
            }
            
            .our-team .social {
              width: 100%;
              padding: 0;
              margin: 0;
              background-color: #1369ce;
              position: absolute;
              bottom: -100px;
              left: 0;
              transition: all 0.5s ease 0s;
            }
            
            .our-team:hover .social {
              bottom: 0;
            }
            
            .our-team .social li {
              display: inline-block;
            }
            
            .our-team .social li a {
              display: block;
              padding: 10px;
              font-size: 17px;
              color: white;
              transition: all 0.3s ease 0s;
              text-decoration: none;
            }
            
            .our-team .social li a:hover {
              color: #1369ce;
              background-color: #f7f5ec;
            }


            @media only screen and (max-width: 600px) {
              .removeselect {
                display: none;
            
                }
            }


            @media only screen and (max-width: 600px) {
              .countersmall {
                font-size: 20px;
                }

              .numbersmall {
                font-size: 24px;
                }
            }
              /* big screen */
            @media only screen and (min-width: 600px) {
              .countersmall {
                font-size: 44px;
                }
                
              .numbersmall {
                font-size: 44px;
                }
            }


            .lookcool2{
              box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
              border-radius:20px;
              background-color: #344e6d;
            }
            .lookcool{
              box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
              border-radius:20px;
            }